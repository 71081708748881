import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Button, Icon } from 'semantic-ui-react';

import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import getSecondsFormatted from '../../utils/getSecondsFormatted';

function NavBar() {
  const history = useHistory();
  const { signOut, signInDate, user } = useAuth();

  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, { name }) => {
      if (name === 'home') {
        history.push('/admin/dashboard');
      }
      if (name === 'reports') {
        history.push('/admin/reports');
      }
    },
    [history],
  );

  const [count, setCount] = useState(
    signInDate + 900 - Math.round(new Date().getTime() / 1e3),
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1);
      if (count <= 0) {
        signOut();
        history.push('/admin');
      }
    }, 1000);
    return (): void => {
      clearTimeout(timer);
    };
  }, [setCount, signOut, history, count]);

  const handleLogout = useCallback(() => {
    signOut();
    history.push('/admin');
  }, [signOut, history]);

  return (
    <Container>
      <Menu size="tiny">
        <Menu.Item name="home" onClick={handleItemClick} />
        <Menu.Item
          name="reports"
          content="Relatório"
          onClick={handleItemClick}
        />

        <Menu.Menu position="right">
          <Menu.Item disabled>
            {`Sua sessão termina em: ${getSecondsFormatted(count)}`}
          </Menu.Item>
          <Menu.Item disabled>
            <Icon name="user" />
            {`${user.nome ? user.nome : ''}`}
          </Menu.Item>
          <Menu.Item>
            <Button
              content="Sign Out"
              icon="sign-out"
              labelPosition="right"
              negative
              onClick={handleLogout}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  );
}

export default NavBar;
