import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import api from '../services/api';

interface UserSession {
  id: number;
  nome: string;
  sindicato: string;
  sigla: string;
}

interface AuthState {
  token: string;
  user: UserSession;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContexData {
  user: UserSession;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  signInDate: number;
}

const AuthContext = createContext<AuthContexData>({} as AuthContexData);

interface AuthProviderProps {
  children: React.ReactNode;
}
function AuthProvider({ children }: AuthProviderProps) {
  const [timeStampSignIn, setTimeStampSignIn] = useState(() => {
    const loginTime = localStorage.getItem('@Suffragium:loginTime');
    if (loginTime) {
      return parseInt(loginTime, 10);
    }
    return 0;
  });

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Suffragium:token');
    const user = localStorage.getItem('@Suffragium:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/usuarios/session', { email, password });

    const { token, usuario } = response.data;

    localStorage.setItem('@Suffragium:token', token);
    localStorage.setItem('@Suffragium:user', JSON.stringify(usuario));

    const loginTime = Math.round(new Date().getTime() / 1e3);
    localStorage.setItem('@Suffragium:loginTime', loginTime.toString());

    const { id, nome, sindicato, sigla } = usuario;
    const user: UserSession = {} as UserSession;
    user.id = id;
    user.nome = nome;
    user.sindicato = sindicato;
    user.sigla = sigla;
    setTimeStampSignIn(loginTime);
    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Suffragium:token');
    localStorage.removeItem('@Suffragium:user');
    localStorage.removeItem('@Suffragium:loginTime');
    setTimeStampSignIn(0);
    setData({} as AuthState);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user: {
        id: data.user?.id,
        nome: data.user?.nome,
        sindicato: data.user?.sindicato,
        sigla: data.user?.sigla,
      },
      token: data.token,
      signIn,
      signOut,
      signInDate: timeStampSignIn,
    }),
    [data, signIn, signOut, timeStampSignIn],
  );
  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContexData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
