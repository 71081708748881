import React, { useCallback, useEffect, useState } from 'react';

import { Stack, Title } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import Template from '../Template';
import FormFuncional from '../../components/FormFuncional';
import FormNascimento from '../../components/FormNascimento';
import FormIdentidade from '../../components/FormIdentidade';

import { useValidation } from '../../hooks/valid';
import { useToast } from '../../hooks/toast';
import FormEmailAndFuncional from '../../components/FormEmailAndFuncional';
import FormEmailAndCpf from '../../components/FormEmailAndCpf';
import { getDateInUTC } from '../../utils/getDateInUTC';

interface ValidationFormData {
  cpf?: string;
  data_nasc?: string;
  funcional?: number;
  email?: string;
  rg?: string;
}

interface AssembleiaData {
  id: string;
  titulo: string;
  recebe_votos: boolean;
}

function Validation() {
  const [assembleia, setAssembleia] = useState<AssembleiaData>(
    {} as AssembleiaData,
  );

  const [countInvalidTry, setCountInvalidTry] = useState(0);

  const { loginVote } = useValidation();
  const { addToast } = useToast();
  const history = useHistory();

  const validateByDataNasc =
    process.env.REACT_APP_EMPREGADOAUTH === 'NASCIMENTO';
  const validateByFuncional =
    process.env.REACT_APP_EMPREGADOAUTH === 'FUNCIONAL';
  const validateByRG = process.env.REACT_APP_EMPREGADOAUTH === 'RG';
  const validateByEmailAndFuncional =
    process.env.REACT_APP_EMPREGADOAUTH === 'EMAIL_FUNCIONAL';

  const validateByEmailAndCpf =
    process.env.REACT_APP_EMPREGADOAUTH === 'EMAIL_CPF';

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;

    const assembleiaStored = sessionStorage.getItem('@Suffragium:assembleia');
    if (!assembleiaStored) {
      history.push('/');
    } else {
      const { id, titulo, recebe_votos } = JSON.parse(assembleiaStored);
      setAssembleia({ id, titulo, recebe_votos });
    }
  }, [history]);

  const handleValidation = useCallback(
    async (data: ValidationFormData) => {
      try {
        const { cpf, data_nasc, funcional, rg, email } = data;
        // Parse date and set timezone to UTC-3
        await loginVote({
          cpf,
          data_nasc: data_nasc ? getDateInUTC(data_nasc) : undefined,
          funcional,
          rg,
          email,
        });
      } catch (err) {
        if (countInvalidTry < 3) {
          setCountInvalidTry(countInvalidTry + 1);
          addToast({
            type: 'error',
            title: 'Dados inválidos!',
            description: 'Verifique as informações e tente novamente.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Máximo de tentativas',
            description:
              'Entre em contato com o sindicato e atualize seus dados.',
          });
          history.push('/contato');
        }
      }
    },
    [countInvalidTry, addToast, history, loginVote],
  );
  return (
    <Template>
      <Stack w="100%" p="lg">
        <Title order={2} ta="center">
          {assembleia?.titulo}
        </Title>
        <Title order={4} ta="center">
          Antes de votar, confirme seus dados abaixo:
        </Title>

        {validateByFuncional && (
          <FormFuncional
            onSubmit={(data: { cpf: string; funcional?: string }) => {
              handleValidation({
                funcional: parseInt(data.funcional || '0', 10),
                cpf: data.cpf,
                data_nasc: undefined,
                rg: undefined,
              });
            }}
            assembleiaId={assembleia?.id}
          />
        )}

        {validateByEmailAndFuncional && (
          <FormEmailAndFuncional
            onSubmit={(data: { email: string; funcional?: string }) => {
              handleValidation({
                funcional: parseInt(data.funcional || '0', 10),
                email: data.email,
              });
            }}
            assembleiaId={assembleia?.id}
          />
        )}

        {validateByEmailAndCpf && (
          <FormEmailAndCpf
            onSubmit={(data: { email: string; cpf?: string }) => {
              handleValidation({
                email: data.email,
                cpf: data.cpf,
              });
            }}
            assembleiaId={assembleia?.id}
          />
        )}
        {validateByDataNasc && (
          <FormNascimento
            onSubmit={(data: { cpf: string; data_nasc?: string }) => {
              handleValidation({
                cpf: data.cpf,
                data_nasc: data.data_nasc,
              });
            }}
            assembleiaId={assembleia?.id}
          />
        )}
        {validateByRG && (
          <FormIdentidade
            onSubmit={(data: { cpf: string; rg?: string }) => {
              handleValidation({
                cpf: data.cpf,
                rg: data.rg,
              });
            }}
            assembleiaId={assembleia?.id}
          />
        )}
      </Stack>
    </Template>
  );
}

export default Validation;
