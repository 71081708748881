import React from 'react';

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

function AdminRoute({ component: Component, ...rest }: RouteProps) {
  const { user } = useAuth();
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return user ? <Component /> : <Redirect to={{ pathname: '/admin' }} />;
      }}
    />
  );
}

export default AdminRoute;
