import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../services/api';

interface Empregado {
  id: number;
  nome: string;
  cpf: string;
}
interface ValidState {
  token: string;
  empregado: Empregado;
}

interface ValidCredentials {
  cpf?: string;
  funcional?: number;
  data_nasc?: string;
  rg?: string;
  email?: string;
}

interface ValidContexData {
  empregado: Empregado;
  token: string;
  loginVote(credentials: ValidCredentials): Promise<void>;
  logoutVote(): void;
}

const ValidContext = createContext<ValidContexData>({} as ValidContexData);

function ValidProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const [data, setData] = useState<ValidState>(() => {
    const token = localStorage.getItem('@Suffragium:token');
    const empregado = localStorage.getItem('@Suffragium:empregado');

    if (token && empregado) {
      return { token, empregado: JSON.parse(empregado) };
    }
    return {} as ValidState;
  });

  const loginVote = useCallback(
    async ({ cpf, data_nasc, funcional, rg, email }: ValidCredentials) => {
      const response = await api.post('votos/session', {
        cpf,
        data_nasc,
        funcional,
        rg,
        email,
      });

      const { token, empregado } = response.data;
      const { id: empregado_id } = empregado;

      const assembleia = sessionStorage.getItem('@Suffragium:assembleia');
      if (assembleia) {
        const { id: assembleia_id } = JSON.parse(assembleia);
        await api
          .get('votos', {
            params: { assembleia_id, empregado_id },
          })
          .then(({ data: voto }) => {
            if (!voto) {
              localStorage.setItem('@Suffragium:token', token);
              localStorage.setItem(
                '@Suffragium:empregado',
                JSON.stringify(empregado),
              );
              setData({ token, empregado });
              history.push('/assembleia/votacao');
              return;
            }

            localStorage.setItem(
              '@Suffragium:voto:exists',
              JSON.stringify(voto),
            );
            history.push('/assembleia/comprovantevoto');
          });
      }
    },
    [history],
  );

  const logoutVote = useCallback(() => {
    localStorage.removeItem('@Suffragium:token');
    localStorage.removeItem('@Suffragium:empregado');
    sessionStorage.removeItem('@Suffragium:assembleia');

    setData({} as ValidState);
  }, []);

  return (
    <ValidContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        empregado: data.empregado,
        token: data.token,
        loginVote,
        logoutVote,
      }}
    >
      {children}
    </ValidContext.Provider>
  );
}

function useValidation(): ValidContexData {
  const context = useContext(ValidContext);

  if (!context) {
    throw new Error('useValidation must be used within a AuthProvider');
  }
  return context;
}

export { ValidProvider, useValidation };
