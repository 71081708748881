import React from 'react';

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useValidation } from '../hooks/valid';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate: boolean | undefined;
  component: React.ComponentType;
}

function VoteRoute({ component: Component, ...rest }: RouteProps) {
  const { empregado } = useValidation();
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return empregado ? <Component /> : <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
}

export default VoteRoute;
