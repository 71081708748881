import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  img {
    width: 20%;
    height: 20%;
  }

  @media (max-width: 450px) {
    img {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #ebf1ed;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin: 5px 0;
    text-align: center;

    input {
      margin-right: 10px;
    }
  }

  select {
    margin: 5px 0;
    padding: 8px;
    background: #f7edf0;
    color: inherit;
  }

  iframe {
    width: 100%;
    height: 390px;
    margin-top: 10px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 10px;
    text-align: center;
    font-size: 22px;
  }

  p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0 40px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  label {
    margin: 20px 0;

    input {
      margin-right: 10px;
    }
  }
`;

export const Documents = styled.div`
  margin-top: 10px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 680px), (max-width: 450px) {
    margin-top: 5px;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      margin-bottom: 5px;
    }
  }
`;
