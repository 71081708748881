import React, { useCallback } from 'react';

import { FaUserAlt, FaLock, FaArrowRight } from 'react-icons/fa';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import { Container, Information } from './styles';
import Template from '../../Template';

interface SignInFormData {
  email: string;
  password: string;
}

function SignIn() {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail inválido'),
      password: (value) => (value ? null : 'Senha obrigatória'),
    },
  });

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um E-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        // console.log('passou ');
        history.push('/admin/dashboard');
      } catch (err) {
        // disparar toast
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro no login. Verifique as informações e tente novamente.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Template>
      <Container>
        <Information>
          <h1>LOGIN ADMIN</h1>
          <p>
            Bem vindo a área administrativa. Informe seus dados para prosseguir:
          </p>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              label="E-mail"
              name="email"
              icon={<FaUserAlt />}
              type="text"
              required
              {...form.getInputProps('email')}
            />

            <TextInput
              label="Senha"
              name="password"
              icon={<FaLock />}
              type="password"
              required
              {...form.getInputProps('password')}
            />

            <Button
              rightIcon={<FaArrowRight />}
              type="submit"
              size="lg"
              color="green"
            >
              ACESSAR
            </Button>
          </form>
        </Information>
      </Container>
    </Template>
  );
}

export default SignIn;
