import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Assembleia from '../pages/Assembleia';
import Validation from '../pages/Validation';
import Votacao from '../pages/Votacao';
import Comprovante from '../pages/Comprovante';
import Contato from '../pages/Contato';

import Dashboard from '../pages/admin/Dashboard';
import SignIn from '../pages/admin/SignIn';
import Reports from '../pages/admin/Reports';
import VoteRoute from './VoteRoute';
import AdminRoute from './AdminRoute';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Assembleia} />
      <Route path="/contato" exact component={Contato} />

      <Route path="/assembleia/validation" component={Validation} />
      <Route path="/assembleia/comprovantevoto" component={Comprovante} />
      <VoteRoute isPrivate path="/assembleia/votacao" component={Votacao} />

      <AdminRoute path="/admin/dashboard" exact component={Dashboard} />
      <AdminRoute path="/admin/reports" exact component={Reports} />
      <Route path="/admin" exact component={SignIn} />
    </Switch>
  );
}

export default Routes;
