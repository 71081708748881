import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  img {
    width: 20%;
    height: 20%;
  }

  @media (max-width: 450px) {
    img {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #ebf1ed;
  padding: 10px;
  border-radius: 8px;
  img {
    align-self: center;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
`;
