import React from 'react';

import { AuthProvider } from './auth';
import { ValidProvider } from './valid';
import { ToastProvider } from './toast';

interface AppProviderProps {
  children: React.ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <AuthProvider>
      <ValidProvider>
        <ToastProvider>{children}</ToastProvider>
      </ValidProvider>
    </AuthProvider>
  );
}

export default AppProvider;
