import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlinePrinter, AiOutlineHome } from 'react-icons/ai';
import { format, utcToZonedTime } from 'date-fns-tz';

import { Button } from '@mantine/core';
import api from '../../services/api';

import { Container, Information, Ticket, ButtonList } from './styles';
import Template from '../Template';

interface Assembleia {
  id: string;
  titulo: string;
}

interface Empregado {
  id: number;
  nome: string;
  cpf: string;
}

interface Voto {
  id: string;
  data_voto: string;
}
interface DadosVoto {
  voto: Voto;
  assembleia: Assembleia;
  empregado: Empregado;
}

function Comprovante() {
  const [dadosVoto, setDadosVoto] = useState<DadosVoto>({} as DadosVoto);
  const history = useHistory();
  const validateByEmailAndFuncional =
    process.env.REACT_APP_EMPREGADOAUTH === 'EMAIL_FUNCIONAL';

  const handleClearLocalStorage = useCallback(() => {
    localStorage.clear();
    history.push('/');
  }, [history]);

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;
    const votoExists = localStorage.getItem('@Suffragium:voto:exists');
    if (!votoExists) {
      const votoNew = localStorage.getItem('@Suffragium:voto:new');
      if (votoNew) {
        const votoNewParsed = JSON.parse(votoNew);
        api
          .get('votos', {
            params: {
              assembleia_id: votoNewParsed.assembleia.id,
              empregado_id: votoNewParsed.empregado.id,
            },
          })
          .then((response) => {
            setDadosVoto(response.data);
          });
      } else {
        history.push('/');
      }
    } else {
      const votoParsed = JSON.parse(votoExists);
      setDadosVoto(votoParsed);
    }

    const onBeforeUnloadFn = (): void => {
      localStorage.clear();
    };

    window.addEventListener('beforeunload', onBeforeUnloadFn);
    return (): void => {
      window.removeEventListener('beforeunload', onBeforeUnloadFn);
    };
  }, [history]);

  const handleImprimir = useCallback(() => {
    window.print();
  }, []);

  return (
    <Template>
      <Container>
        <Information>
          <h1>{dadosVoto.assembleia?.titulo}</h1>
          <h2>COMPROVANTE DE VOTAÇÃO</h2>

          <Ticket>
            <h3>NOME EMPREGADO</h3>
            <span>{dadosVoto.empregado?.nome}</span>

            {!validateByEmailAndFuncional && (
              <>
                <h3>CPF EMPREGADO</h3>
                <span>{dadosVoto.empregado?.cpf}</span>
              </>
            )}

            <h3>ID VOTO</h3>
            <span>{dadosVoto.voto?.id}</span>

            <h3>DATA E HORA DO VOTO</h3>
            <span>
              {dadosVoto.voto?.data_voto
                ? format(
                    utcToZonedTime(
                      dadosVoto.voto?.data_voto,
                      'America/Sao_Paulo',
                    ),
                    'dd/MM/yyyy - HH:mm:ss',
                  )
                : ''}
            </span>
          </Ticket>

          <ButtonList>
            <Button
              rightIcon={<AiOutlineHome />}
              onClick={handleClearLocalStorage}
              size="lg"
            >
              Voltar a página inicial
            </Button>

            <Button
              rightIcon={<AiOutlinePrinter />}
              type="button"
              onClick={handleImprimir}
              size="lg"
            >
              Imprimir Comprovante
            </Button>
          </ButtonList>
        </Information>
      </Container>
    </Template>
  );
}

export default Comprovante;
