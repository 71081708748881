import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  LabelProps,
} from 'recharts';

import { format, utcToZonedTime } from 'date-fns-tz';
import { Header, Container } from './styles';
import NavBar from '../../../components/NavBar';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import Template from '../../Template';

interface DataChart {
  name: string;
  total: number;
  fill: string;
}

interface AssembleiaData {
  id: string;
  titulo: string;
  data_inicio: string;
  data_fim: string;
  ativa?: boolean;
  perguntas: [
    {
      id: number;
      opcoes: [
        {
          id: number;
          label: string;
        },
      ];
      pergunta: string;
    },
  ];
  recebe_votos?: boolean;
  total_votos?: number;
}

interface CustomizedLabelProps extends LabelProps {
  isByPercentualChecked: boolean;
}

function CustomizedLabel({
  x = 0,
  y,
  fill,
  width = 1,
  height,
  value,
  isByPercentualChecked,
}: CustomizedLabelProps): JSX.Element {
  return (
    <text
      x={x}
      y={y}
      fill={fill}
      width={width}
      height={height}
      textAnchor="middle"
      dy={-6}
    >
      {isByPercentualChecked ? `${value}%` : `${value}`}
    </text>
  );
}

function DashboardBody({
  dataReport,
  isByPercentualChecked,
  assembleia,
}: {
  dataReport: DataChart[][];
  isByPercentualChecked: boolean;
  assembleia: AssembleiaData;
}): JSX.Element {
  return (
    <>
      <h4>
        {assembleia.data_inicio &&
          `Periodo de votação: ${format(
            utcToZonedTime(assembleia.data_inicio, 'America/Sao_Paulo'),
            'dd/MM/yyyy - HH:mm',
          )} - ${format(
            utcToZonedTime(assembleia.data_fim, 'America/Sao_Paulo'),
            'dd/MM/yyyy - HH:mm',
          )}
        `}
      </h4>

      <h3>Resultado Total da Assembleia:</h3>
      {dataReport.map((data, index) => (
        <>
          <h3>{`Pergunta: ${assembleia.perguntas[index].pergunta}`}</h3>
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />

            <Tooltip />

            <Bar
              dataKey="total"
              barSize={20}
              fill="#223123"
              label={
                <CustomizedLabel
                  isByPercentualChecked={isByPercentualChecked}
                />
              }
            />
          </BarChart>
        </>
      ))}

      {!isByPercentualChecked && (
        <h2>{`Total de votos da assembleia: ${assembleia.total_votos}`}</h2>
      )}

      <h3>{`ID único da Assembleia: ${assembleia.id}`}</h3>
      <h4>
        {`Relatório Emitido em: ${format(
          utcToZonedTime(new Date(), 'America/Sao_Paulo'),
          'dd/MM/yyyy - HH:mm:ss',
        )}`}
      </h4>
    </>
  );
}

function Dashboard() {
  const { token } = useAuth();
  const [dataReport, setDataReport] = useState<DataChart[][]>([]);
  const [assembleia, setAssembleia] = useState<AssembleiaData>(
    {} as AssembleiaData,
  );
  const [assembleias, setAssembleias] = useState<AssembleiaData[]>([]);
  const [loadGraphic, setLoadGraphic] = useState(false);
  const [assembleiaId, setAssembleiaId] = useState('');
  const [isByPercentualChecked, setByPercentualChecked] = useState(false);

  const handleByPercentualCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setByPercentualChecked(e.target.checked);
    },
    [setByPercentualChecked],
  );

  const handleAPItoReport = useCallback(
    async (id: string) => {
      const colorsChart = [
        '#227129',
        '#f23411',
        '#1c2541',
        '#ff9f1c',
        '#f23bb3',
      ];

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      if (id !== '') {
        const response = await api.get(
          `votos/reports/all?assembleia_id=${id}`,
          config,
        );

        if (response.data.length === 0) {
          setLoadGraphic(false);
          setDataReport([]);
        }

        if (response.data) {
          const { assembleia: assembleiaData, votos, totais } = response.data;
          const totalVotosAssembleia = votos.length;
          setAssembleia({
            id: assembleiaData.id,
            titulo: assembleiaData.titulo,
            data_inicio: assembleiaData.data_inicio,
            data_fim: assembleiaData.data_fim,
            perguntas: assembleiaData.opcoes,
            total_votos: totalVotosAssembleia,
          });

          const chartDataList = Object.keys(totais)
            .map((key: string) => {
              return totais[key];
            })
            .map((value) => {
              return Object.keys(value).map(
                (keyTotais: string, indexTotais: number) => {
                  if (isByPercentualChecked) {
                    return {
                      name: keyTotais,
                      total: Math.round(
                        (value[keyTotais] * 100) / totalVotosAssembleia,
                      ),
                      fill: colorsChart[indexTotais],
                    };
                  }

                  return {
                    name: keyTotais,
                    total: value[keyTotais],
                    fill: colorsChart[indexTotais],
                  } as DataChart;
                },
              );
            });

          setDataReport(chartDataList);
          setLoadGraphic(chartDataList.length > 0);
        }
      }
    },
    [token, isByPercentualChecked],
  );

  const handlePopulateSelect = useCallback(async () => {
    await api.get('assembleias/?type=select').then((response) => {
      let assembleiasData: Array<AssembleiaData> = [] as Array<AssembleiaData>;
      response.data.forEach((assembleiaData: AssembleiaData): void => {
        const {
          id,
          titulo,
          data_inicio,
          data_fim,
          ativa,
          recebe_votos,
          perguntas,
        } = assembleiaData;

        assembleiasData = [
          ...assembleiasData,
          {
            id,
            titulo,
            data_inicio,
            data_fim,
            ativa,
            recebe_votos,
            perguntas,
          },
        ];
      });
      setAssembleiaId(assembleiasData[0]?.id || '');
      setAssembleias(assembleiasData);
    });
  }, []);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      if (value) {
        handleAPItoReport(value);
      }
    },
    [handleAPItoReport],
  );

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;
    handlePopulateSelect();
    handleAPItoReport(assembleiaId);
  }, [handlePopulateSelect, assembleiaId, handleAPItoReport]);

  return (
    <Template>
      <NavBar />
      <Container>
        <Header>
          <h3>Selecione a Assembleia para exibir os dados:</h3>
          <select
            name="assembleiasList"
            id="assembleiasList"
            onChange={handleSelectChange}
          >
            {assembleias.map((option, index) => {
              return (
                <option
                  value={option.id}
                  key={option.id}
                  selected={index === 0}
                >
                  {option.titulo}
                </option>
              );
            })}
          </select>
          <label htmlFor="totaisCheck">
            <input
              type="checkbox"
              name="totaisCheck"
              id="totaisCheck"
              checked={isByPercentualChecked}
              onChange={handleByPercentualCheck}
            />
            <strong>Totais em Percentual</strong>
          </label>
        </Header>
        <h2>{assembleia.titulo}</h2>

        {loadGraphic ? (
          <DashboardBody
            dataReport={dataReport}
            isByPercentualChecked={isByPercentualChecked}
            assembleia={assembleia}
          />
        ) : null}
      </Container>
    </Template>
  );
}

export default Dashboard;
