export function getDateInUTC(date: string): string | undefined {
  if (!date) {
    return undefined;
  }
  const dateParts = date.split('/');
  const dateParsed = new Date(
    `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00:00-03:00`,
  ); // Parse date and set timezone to UTC-3

  const utcDate = new Date(
    dateParsed.getTime() + dateParsed.getTimezoneOffset() * 60 * 1000,
  ); // Convert to UTC
  return utcDate.toISOString();
}
