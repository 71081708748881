import React, { useEffect, useState } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Button } from '@mantine/core';
import { Container, Information } from './styles';
import Template from '../Template';

function Contato() {
  const [nomeSindicato, setNomeSindicato] = useState('');

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    setNomeSindicato(sindicato || '');

    document.title = `Votação Online ${sindicato}`;
  }, []);

  return (
    <Template>
      <Container>
        <Information>
          {nomeSindicato === 'SAEMAC' && (
            <>
              <h1>DÚVIDAS OU PROBLEMAS AO VOTAR?</h1>
              <span>Atendimento em horário especial durante a votação.</span>

              <h2>Segunda e Terça - das 8h as 17h</h2>
              <span>045 9101-5161</span>
            </>
          )}

          {nomeSindicato === 'SIQUIM' && (
            <>
              <h1>DÚVIDAS OU PROBLEMAS AO VOTAR?</h1>
              <span>Atendimento em horário especial durante a votação.</span>

              <h2>Segunda e Terça - das 8h as 17h</h2>
              <span>041 98516-9935</span>
              <span>041 3026-5748</span>
            </>
          )}

          {nomeSindicato === 'SINTEC' && (
            <>
              <h1>DÚVIDAS OU PROBLEMAS AO VOTAR?</h1>
              <span>Atendimento em horário especial durante a votação.</span>

              <h2>Segunda das 8h às 18h</h2>
              <span>041 99153-4744 (WhatsApp SINTEC)</span>
              <span>041 98820-2024</span>
            </>
          )}
          <Button leftIcon={<AiOutlineHome />} size="lg">
            <Link to="/">Voltar a página inicial</Link>
          </Button>
        </Information>
      </Container>
    </Template>
  );
}

export default Contato;
