import React from 'react';

import { Container, Footer, Header } from './styles';

interface TemplateProps {
  children: React.ReactNode;
}

function Template({ children }: TemplateProps) {
  return (
    <>
      <Header>
        <img src={process.env.REACT_APP_LOGOAPP} alt="logo_app" />
        <img src={process.env.REACT_APP_LOGOSINDICATO} alt="logo_sindicato" />
      </Header>
      <Container>{children}</Container>
      <Footer>
        <span>
          Desenvolvido por{' '}
          <a
            href="https://www.codeea.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            Codeea
          </a>
        </span>
      </Footer>
    </>
  );
}

export default Template;
