import styled from 'styled-components';

export const Header = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  background: #ebf1ed;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    strong {
      padding-left: 5px;
    }
  }

  select {
    margin: 5px 0;
    padding: 8px;
    background: #f7edf0;
    color: inherit;
  }

  h1 {
    text-align: center;
    font-size: 22px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 3px 0;
  }
`;
