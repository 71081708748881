import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  margin-top: 0;
  padding: 20px 40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 450px) {
    img {
      width: 40%;
      height: 40%;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #ebf1ed;
  padding: 10px;
  border-radius: 8px;
  img {
    align-self: center;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 22px;
    font-size: 22px;
    text-align: center;
  }
  h2,
  h3,
  h4 {
    margin-top: 5px;
    text-align: center;
  }

  p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
  }

  @media (max-width: 680px), (max-width: 450px) {
    h1 {
      margin: 0;
      font-size: 16px;
    }
    h2 {
      font-size: 14px;
    }

    p {
      line-height: 10px;
      font-size: 12px;
      padding: 0 5px;
      text-align: justify;
    }

    label {
      margin: 10px 0;

      input {
        margin-right: 10px;
      }
    }
  }
`;

export const Ticket = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  padding: 10px;

  width: 350px;

  background: #fff;
  border-radius: 8px;
  border: 1px #213544 solid;
  span {
    text-align: center;
  }

  @media (max-width: 450px) {
    margin: 7px 0;
    padding: 5px;
    width: 280px;
    h1 {
      margin: 0;
      font-size: 18px;
      text-align: center;
    }
    h2,
    h3,
    h4 {
      margin: 0;
      text-align: center;
      font-size: 16px;
    }
    span {
      margin-bottom: 5px;
    }
  }
`;

export const ButtonList = styled.div`
  margin-top: 10px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  a {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
  }

  @media (max-width: 680px), (max-width: 450px) {
    margin-top: 5px;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      margin-bottom: 5px;
    }
  }
`;
